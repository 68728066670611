export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [7,[2]],
		"/(landing)/about": [20],
		"/(app)/admin": [8,[2,3]],
		"/auth": [~29],
		"/(landing)/blog": [21],
		"/(app)/c/[id]": [9,[2]],
		"/(app)/documents": [10,[2]],
		"/(landing)/download": [~22],
		"/(landing)/download/mac": [23],
		"/error": [30],
		"/(landing)/legal": [~24],
		"/(landing)/legal/privacy-policy": [~25],
		"/(landing)/login": [26,[5]],
		"/(landing)/pricing": [27],
		"/(landing)/signup": [28,[6]],
		"/s/[id]": [31],
		"/(app)/workspace": [11,[2,4]],
		"/(app)/workspace/documents": [12,[2,4]],
		"/(app)/workspace/models": [13,[2,4]],
		"/(app)/workspace/models/create": [14,[2,4]],
		"/(app)/workspace/models/edit": [15,[2,4]],
		"/(app)/workspace/playground": [16,[2,4]],
		"/(app)/workspace/prompts": [17,[2,4]],
		"/(app)/workspace/prompts/create": [18,[2,4]],
		"/(app)/workspace/prompts/edit": [19,[2,4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';